<template>
  <form
    v-if="tattooerStudio"
    action
    data-vv-scope="tattooer-form"
    @submit.prevent="validateForm('tattooer-form')"
  >
    <v-row>
      <v-col class="py-0" cols="12">
        <h3
          class="primary--text"
          style="font-weight: normal; text-decoration: underline"
        >
          Dias de la estancia
        </h3>
      </v-col>
      <v-col class="py-0" cols="12" md="3">
        <label>{{ $t("from") }}</label>
        <v-menu
          ref="menu"
          tabindex="13"
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              dense
              v-model="computedFromFormatted"
              data-vv-validate-on="blur"
              v-validate="'required'"
              data-vv-name="from"
              color="secondary"
              class="pointer"
              :error-messages="errors.collect('tattooer-form.from')"
              readonly
              v-on="on"
            >
              <template v-slot:prepend-inner>
                <v-icon small class="mt-1" color="primary">
                  $appointments</v-icon
                >
              </template>
            </v-text-field>
          </template>

          <v-date-picker
            first-day-of-week="1"
            v-model="tattooerStudio.from"
            color="primarys"
            no-title
            scrollable
            :show-current="today"
            :min="today"
            @input="menuFrom = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0" cols="12" md="2">
        <v-checkbox
          v-model="tattooerStudio.indefinido"
          :label="$t('tattooers.invites.indef')"
          class="mt-8"
          @input="tattooerStudio.to == null"
        ></v-checkbox>
      </v-col>
      <v-col class="py-0" v-if="!tattooerStudio.indefinido" cols="12" md="3">
        <label>{{ $t("to") }}</label>

        <v-menu
          ref="menu"
          tabindex="13"
          v-model="menuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              dense
              v-model="computedToFormatted"
              color="secondary"
              prepend-inner-icon="$calendar"
              v-validate="'required'"
              data-vv-name="to"
              :error-messages="errors.collect('tattooer-form.to')"
              class="pointer"
              readonly
              v-on="on"
              ><template v-slot:prepend-inner>
                <v-icon small class="mt-1" color="primary">
                  $appointments</v-icon
                >
              </template>
            </v-text-field>
          </template>

          <v-date-picker
            first-day-of-week="1"
            v-model="tattooerStudio.to"
            color="primary"
            no-title
            scrollable
            :show-current="today"
            :min="tattooerStudio.from"
            @input="menuTo = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="py-0" cols="3">
        <v-dialog v-model="dialog" width="600">
          <template v-slot:activator="{ on }">
            <v-btn style="height: 30px" block outlined class="mt-7" v-on="on">
              {{ $t("timetable", { name: "" }) }}
            </v-btn>
          </template>
          <v-card>
            <v-card id="Token">
              <v-card-title
                style="text-decoration: underline; text-transform: uppercase"
                >{{ $t("timetable", { name: "" }) }}
              </v-card-title>
              <div class="close">
                <v-btn icon @click="dialog = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <timetable
                ref="timetable"
                :timetable="tattooerStudio.timetable"
                :timetableLimit="studio.timetable"
                :daysAvailable="timetableAvailable"
              ></timetable>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  @click="dialog = false"
                  style="height: 30px; width: 100px"
                  elevation="0"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  @click="
                    $refs.timetable.check() ? (dialog = false) : (dialog = true)
                  "
                  elevation="0"
                  style="height: 30px; width: 100px !important"
                  ><v-icon small style="margin-right: 5px">$save</v-icon
                  >{{ $t("save", { name: "" }) }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <div class="pa-3 text-right" v-if="buttons !== false">
      <v-btn outlined style="height: 30px" @click="$emit('back')">
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>

      <v-btn
        color="primary"
        type="submit"
        style="height: 30px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "StudioInscription",
  props: ["tattooerStudio", "buttons"],
  components: {
    Timetable: () => import("@/components/ui/Timetable"),
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
  computed: {
    ...mapState("inscriptions", ["studioState"]),

    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },

    computedFromFormatted() {
      if (!this.tattooerStudio.from) return null;
      const [year, month, day] = this.tattooerStudio.from.split("-");
      return `${day}/${month}/${year}`;
    },
    computedToFormatted() {
      if (!this.tattooerStudio.to) return null;
      const [year, month, day] = this.tattooerStudio.to.split("-");
      return `${day}/${month}/${year}`;
    },
    timetableAvailable() {
      let result = [1, 1, 1, 1, 1, 1, 1];
      let end = this.tattooerStudio.to;
      let start = this.tattooerStudio.from;
      if (start != null && end != null) {
        let dataini, datafi;
        start = start.split("-");
        dataini = new Date(start[0], start[1] - 1, start[2], "12", "00");
        end = end.split("-");
        datafi = new Date(end[0], end[1] - 1, end[2], "12", "00");

        let diaini = (dataini.getUTCDay() - 1) % 7;
        let diafi = (datafi.getUTCDay() - 1) % 7;
        if (diaini == -1) diaini = 6;
        if (datafi == -1) diafi = 6;

        if ((datafi - dataini) / (1000 * 60 * 60 * 24) > 7) {
          return result;
        } else if (diaini == diafi) {
          for (let i = 0; i < result.length; ++i) {
            if (i != diaini) result[i] = 0;
          }
        } else if (diaini < diafi) {
          for (let i = diaini; i <= diafi; ++i) {
            result[i] = 2;
          }
          for (let i = 0; i < result.length; ++i) result[i]--;
        } else if (diaini > diafi) {
          for (let i = diaini; i < result.length; ++i) {
            result[i] = 2;
          }
          for (let i = 0; i <= diafi; ++i) {
            result[i] = 2;
          }
          for (let i = 0; i < result.length; ++i) {
            result[i]--;
          }
        }
      }
      return result;
    },
  },
  methods: {
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      let time_val = this.$refs.timetable.check();
      this.$validator.validateAll(scope).then((result) => {
        if (result && time_val) {
          //console.log("validated");
          this.$emit("validated", this.tattooerStudio);
          return true;
        }
        return false;
      });
    },
  },
  data() {
    return { menuFrom: false, menuTo: false, today: null, dialog: false };
  },
};
</script>